import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_loading_skeleton = _resolveComponent("loading-skeleton")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('myStickers')
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, { class: "ion-text-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { fixed: "" }, {
          default: _withCtx(() => [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_loading_skeleton, { key: 0 }))
              : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStickers, (sticker) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: sticker.id,
                        onClick: ($event: any) => (_ctx.openSticker(sticker.id)),
                        button: "",
                        detail: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                style: {"max-height":"150px"},
                                src: sticker.outputPhotoBase64 || sticker.outputPhoto
                              }, null, 8, _hoisted_1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, _toDisplayString(sticker.name), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.t('lastUpdated')) + _toDisplayString(_ctx.formatDate(sticker.updatedAt)), 1),
                              _withDirectives(_createVNode(_component_ion_badge, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('designing')), 1)
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, _ctx.currStickerId == sticker.id]
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}