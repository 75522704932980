
// Vue reactivity
import { computed, defineComponent } from 'vue';

// icons
import { arrowBack, arrowBackOutline, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonList, IonItem, IonLabel, IonThumbnail, IonBadge,
        modalController, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'StickerListModal',
  props: ['currStickerId'],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonList, IonItem, IonLabel, IonThumbnail, IonBadge,
                LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { formatDate } = utils();

    const loading = computed(() => store.state.loadingUserStickers);
    const userStickers = computed(() => store.getters.orderedUserStickers);

    const closeModal = async () => { await modalController.dismiss() };

    const openSticker = (stickerId: any) => {
      closeModal();
      router.replace(`/stickers/${stickerId}`);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, close,

      // variables
      loading, userStickers,

      // methods
      t, openSticker, closeModal, formatDate
    }
  }
})
