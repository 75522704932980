import { utilsDesign } from "./utilsDesign";

const { releaseHTMLCanvases } = utilsDesign();

function pixelAt(idata, x, y) {
  let idx = (y * idata.width + x) * 4;
  const d = [];
  d.push(
    idata.data[idx++],
    idata.data[idx++],
    idata.data[idx++],
    idata.data[idx++]
  );
  return d;
}

function rgbDistance(p1, p2) {
  return Math.sqrt(
    Math.pow(p1[0] - p2[0], 2) +
    Math.pow(p1[1] - p2[1], 2) +
    Math.pow(p1[2] - p2[2], 2)
  );
}

function rgbMean(pTab) {
  const m = [0, 0, 0];

  for (let i = 0; i < pTab.length; i++) {
    m[0] += pTab[i][0];
    m[1] += pTab[i][1];
    m[2] += pTab[i][2];
  }

  m[0] /= pTab.length;
  m[1] /= pTab.length;
  m[2] /= pTab.length;

  return m;
}

function backgroundMask(idata, thres = 10) {
  const rgbv_no = pixelAt(idata, 0, 0);
  const rgbv_ne = pixelAt(idata, idata.width - 1, 0);
  const rgbv_so = pixelAt(idata, 0, idata.height - 1);
  const rgbv_se = pixelAt(idata, idata.width - 1, idata.height - 1);

  if (
    rgbDistance(rgbv_no, rgbv_ne) < thres &&
    rgbDistance(rgbv_ne, rgbv_se) < thres &&
    rgbDistance(rgbv_se, rgbv_so) < thres &&
    rgbDistance(rgbv_so, rgbv_no) < thres
  ) {
    // Mean color
    const mean = rgbMean([rgbv_ne, rgbv_no, rgbv_se, rgbv_so]);

    // Mask based on color distance
    const mask = [];
    //for (let i = 0; i < idata.width * idata.height; i++) {
    for (let i = 0; i < idata.data.length; i++) {
      const d = rgbDistance(mean, [
        //idata.data[i * 4],
        //idata.data[i * 4 + 1],
        //idata.data[i * 4 + 2],
        idata.data[i],
        idata.data[i + 1],
        idata.data[i + 2],
      ]);
      mask[i] = d < thres ? 0 : 255;
    }

    return mask;
  }
}

function applyMask(idata, mask) {
  for (let i = 0; i < idata.width * idata.height; i++) {
    idata.data[i] = mask[i];
    //idata.data[4 * i + 3] = mask[i];
  }
}
function gradient(a, b) {
  return (b.y-a.y)/(b.x-a.x);
}

export function SoftImageEdge(imageData) {
  const { width, height } = imageData;
  const edgeBlurSize = this.blurRadius() || 0;

  // 1. Mask the image
  const mask = backgroundMask(imageData);
  const maskedImageData = mask ? new ImageData( new Uint8ClampedArray(mask), width, height ) : imageData;
  
  // Original Image
  const imgCanvas = document.createElement('canvas');
  imgCanvas.width = width;
  imgCanvas.height = height;
  imgCanvas.getContext('2d').putImageData(imageData, 0, 0);
  
  // Masked Image
  const maskCanvas = document.createElement('canvas');
  maskCanvas.width = width;
  maskCanvas.height = height;
  const maskCtx = maskCanvas.getContext('2d');
  maskCtx.putImageData(maskedImageData, 0, 0);

  // Scale the mask image
  const scaledMaskCanvas = document.createElement('canvas');
  scaledMaskCanvas.width = width;
  scaledMaskCanvas.height = height;
  const scale = (50 - edgeBlurSize / 4) / 50;
  const scaledMaskCtx = scaledMaskCanvas.getContext('2d');
  scaledMaskCtx.scale(scale, scale);
  const x = (width / scale - width) / 2;
  const y = (height / scale - height) / 2;
  scaledMaskCtx.drawImage(maskCanvas, x, y);
  
  // 2. Temp Canvas (for manipulating the image)
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  // draw the mask image first (with filter)
  if (ctx.filter === "none") {
    ctx.filter = `blur(5px)`;
    //ctx.filter = `blur(${edgeBlurSize}px)`;
    ctx.drawImage(scaledMaskCanvas, 0, 0);
    ctx.filter = "none";
  }
  else { // Safari (iOS)
    ctx.shadowOffsetX = width;
    ctx.shadowBlur = 10;
    //ctx.shadowBlur = edgeBlurSize * 2;
    ctx.shadowColor = "black";
    ctx.drawImage(scaledMaskCanvas, -width, 0);
  }

  // then draw the original image on top of it (source-in)
  ctx.globalCompositeOperation = 'source-in';
  ctx.drawImage(imgCanvas, 0, 0);

  // 3. Update the input image data
  const newImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  for (let i = 0; i < newImageData.data.length; i++) {
    imageData.data[i] = newImageData.data[i];
  }

  // 4. Clean-up (free the memory)
  releaseHTMLCanvases([imgCanvas, maskCanvas, scaledMaskCanvas, canvas]);
}