import { SoftImageEdge } from './SoftImageEdge';

export function useKonvaCustomFilters() {
  const canvas = document.createElement('canvas');
  const tempCanvas = document.createElement('canvas');
  let lastUsedMaskImgData;

  // make all pixels opaque 100% (except pixels that 100% transparent)
  function removeTransparency(canvas) {
    const ctx = canvas.getContext('2d');

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const nPixels = imageData.data.length;
    for (let i = 3; i < nPixels; i += 4) {
      if (imageData.data[i] > 0) {
        imageData.data[i] = 255;
      }
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  }
  function Border(imageData) {
    const nPixels = imageData.data.length;

    const color = this.getAttr('borderColor') || 'white';
    const borderSize = this.getAttr('borderSize') || 100;
    const offset = 100;

    // - first set correct dimensions for canvases
    canvas.width = imageData.width;
    canvas.height = imageData.height;

    tempCanvas.width = imageData.width;
    tempCanvas.height = imageData.height;

    // - then draw original shape into temp canvas
    tempCanvas.getContext('2d').putImageData(imageData, 0, 0);
    
    let newImageData;

    if (lastUsedMaskImgData) {
      newImageData = new ImageData(
        new Uint8ClampedArray(lastUsedMaskImgData.data),
        lastUsedMaskImgData.width,
        lastUsedMaskImgData.height
      );
    }
    else {
      // - then we need to remove alpha chanel, because it will affect shadow (transparent shapes has smaller shadow)
      removeTransparency(tempCanvas);

      const ctx = canvas.getContext('2d');

      // 3. we will use shadow as border
      // so we just need apply shadow on the original image
      ctx.save();
      ctx.shadowColor = color;
      ctx.shadowBlur = borderSize;
      ctx.drawImage(tempCanvas, 0, 0);
      ctx.restore();

      // - Then we will dive in into image data of [original image + shadow]
      // and remove transparency from shadow
      const tempImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      const SMOOTH_MIN_THRESHOLD = 3;
      const SMOOTH_MAX_THRESHOLD = 10;

      let val, hasValue;

      for (let i = 3; i < nPixels; i += 4) {
        // skip opaque pixels
        if (imageData.data[i] === 255) {
          continue;
        }
        val = tempImageData.data[i];
        hasValue = val !== 0;
        if (!hasValue) {
          continue;
        }
        if (val > SMOOTH_MAX_THRESHOLD) {
          val = 255;
        } else if (val < SMOOTH_MIN_THRESHOLD) {
          val = 0;
        } else {
          val = ((val - SMOOTH_MIN_THRESHOLD) / (SMOOTH_MAX_THRESHOLD - SMOOTH_MIN_THRESHOLD)) * 255;
        }
        tempImageData.data[i] = val;
      }

      // draw resulted image (original + shadow without opacity) into canvas
      ctx.putImageData(tempImageData, 0, 0);
      //console.log(canvas.toDataURL('image/png'));

      // then fill whole image with color (after that shadow is colored)
      ctx.save();
      ctx.globalCompositeOperation = 'source-in';
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.restore();
      //console.log(canvas.toDataURL('image/png'));

      // then we need to copy colored shadow into original imageData
      newImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    }

    const indexesToProcess = [];
    for (let i = 3; i < nPixels; i += 4) {
      const hasTransparentOnTop = imageData.data[i - imageData.width * 4 * offset] === 0;
      const hasTransparentOnTopRight = imageData.data[i - (imageData.width * 4 + 4) * offset] === 0;
      const hasTransparentOnTopLeft = imageData.data[i - (imageData.width * 4 - 4) * offset] === 0;
      const hasTransparentOnRight = imageData.data[i + 4 * offset] === 0;
      const hasTransparentOnLeft = imageData.data[i - 4 * offset] === 0;
      const hasTransparentOnBottom = imageData.data[i + imageData.width * 4 * offset] === 0;
      const hasTransparentOnBottomRight = imageData.data[i + (imageData.width * 4 + 4) * offset] === 0;
      const hasTransparentOnBottomLeft = imageData.data[i + (imageData.width * 4 - 4) * offset] === 0;
      const hasTransparentAround =
        hasTransparentOnTop ||
        hasTransparentOnRight ||
        hasTransparentOnLeft ||
        hasTransparentOnBottom ||
        hasTransparentOnTopRight ||
        hasTransparentOnTopLeft ||
        hasTransparentOnBottomRight ||
        hasTransparentOnBottomLeft;

      // if pixel presented in original image - skip it
      // because we need to change only shadow area
      if (imageData.data[i] === 255 || (imageData.data[i] && !hasTransparentAround)) {
        continue;
      }
      if (!newImageData.data[i]) {
        // skip transparent pixels
        continue;
      }
      indexesToProcess.push(i);
    }

    for (let index = 0; index < indexesToProcess.length; index += 1) {
      const i = indexesToProcess[index];
      const alpha = imageData.data[i] / 255;

      imageData.data[i] = newImageData.data[i];
      imageData.data[i - 1] = newImageData.data[i - 1] * (1 - alpha) + imageData.data[i - 1] * alpha;
      imageData.data[i - 2] = newImageData.data[i - 2] * (1 - alpha) + imageData.data[i - 2] * alpha;
      imageData.data[i - 3] = newImageData.data[i - 3] * (1 - alpha) + imageData.data[i - 3] * alpha;
    }

    if (lastUsedMaskImgData) {
      lastUsedMaskImgData = null;
    } else {
      //lastUsedMaskImgData = new ImageData(new Uint8ClampedArray(imageData.data), imageData.width, imageData.height);
    }
  }
  return {
    Border,
    SoftImageEdge,
  }
}