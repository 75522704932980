
// Vue reactivity
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';

// icons
import { checkmark, close, chevronBack, chevronForward, logoWhatsapp, shareSocialOutline, downloadOutline,
        swapHorizontal, chevronUp, chevronDown, eyeOutline, eyeOffOutline,
        arrowUndoOutline, arrowRedoOutline, cameraOutline, colorWandOutline, createOutline, add, text, scan,
        ellipsisVertical, ellipsisHorizontal, folderOutline, copyOutline, duplicateOutline, trashOutline, crop,
        removeCircleOutline, remove, arrowForward, arrowBack, refresh, } from 'ionicons/icons';

// components
import { IonPage, IonGrid, IonRow, IonCol, IonHeader, IonToolbar, IonTitle, IonLabel, IonContent, IonChip,
        IonBackButton, IonButtons, IonButton, IonIcon, IonSegment, IonSegmentButton, IonRange,
        IonSpinner, IonList, IonCard, IonThumbnail, IonItem, IonPopover, IonSelect, IonSelectOption,
        IonBadge, IonText, IonModal, IonToggle,
        alertController, modalController, loadingController, } from '@ionic/vue';
import StickerPreQuestionModal from '@/components/modals/StickerPreQuestionModal.vue';

import ColorThief from 'colorthief';
import ColorPicker from 'simple-color-picker';
import Konva from 'konva';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { utils } from '@/composables/utils';
import { utilsDesign } from '@/composables/utilsDesign';
import { usePhotoGallery, Photo } from '@/composables/usePhotoGallery';
import { useKonvaCustomFilters } from '@/composables/useKonvaCustomFilters';
import { Directory } from '@capacitor/filesystem';
import StickerService from '@/services/StickerService';

// CSS
import '@/theme/diy.css';

export default defineComponent({
  name: 'DIYProductDesignModal',
  props: ["product", "sticker", "sceneWidth", "sceneHeight"],
  components: { IonPage, IonGrid, IonRow, IonCol, IonHeader, IonToolbar, IonTitle, IonLabel, IonContent, IonChip,
                IonBackButton, IonButtons, IonButton, IonIcon, IonSegment, IonSegmentButton, IonRange,
                IonSpinner, IonList, IonCard, IonThumbnail, IonItem, IonPopover, IonSelect, IonSelectOption,
                IonBadge, IonText, IonModal, IonToggle, },
  setup(props) {
    Konva.hitOnDragEnabled = true; // detect gesture

    // recommended bg colors
    const colorThief = new ColorThief(); // for checking dominant colors in images
    const getDominantColorCodes = (img: any) => (colorThief.getPalette(img, 4).map(numbers => `rgb(${numbers.join(", ")})`));

    // methods or composables
    const { t } = useI18n();
    const router = useRouter();
    const { sleep, arraymove, presentToast, presentPrompt, scrollToRefElement, loadHTMLImg, readFileFromDevice, writeFileToDevice,
            uniqueId, getCorner, getClientRect, getLocalizedStr, isNumber, openImageModal, } = utils();
    const { photoFilters, setPanelModalBreakpoint, getCenter, getDistance, isPinching, addFiltersToShape,
            getDBCanvasData, checkAttachTransformer, clearTransformerSelection, onTrDragMove, refreshLineGuides,
            initMaterials, getShapeDataURL, concatCupPreviewPhotos,
            releaseHTMLCanvases, getTrimmedCanvasDataURL, clearKonvaDOMCanvases, resetCustomShapeFilterState,
            navigateMaterialCategories, FONT_FAMILY_OPTIONS, OUTPUT_STICKER_WIDTH, } = utilsDesign();
    const { takePhoto, } = usePhotoGallery();
    const { Border, SoftImageEdge } = useKonvaCustomFilters();
    const setModalBreakpoint = (breakpoint) => (setPanelModalBreakpoint(breakpoint, '#product_panel-modal'))

    // Vuex Store
    const store = useStore();

    // State variables
    const dummy = ref("dummy"); // for segment buttons (no highlight select)
    const materials = computed(() => store.state.materials);
    const materialCategories = computed(() => store.state.materialCategories);
    const settings = computed(() => store.state.settings);

    // Popover
    const popoverState = reactive({
      productColor: false,
      textColor: false,
    })
    const popoverEvent = ref();
    const isColorPopoverOpenRef = ref(false);
    const colorPopoverEvent = ref();

    // Canvas Configutations / Helpers
    let textColorPicker, productColorPicker;
    let loadCanvasTimeout: any = null;

    const filterStageRef = ref(null), stageRef = ref(null);
    const transformer = ref(null);
    const screenWidth = Math.min(window.innerWidth-10, 400);

    const canvas = reactive({
      shapeScale: 1, // differ according to product sizes
      designLayerScale: 1,
      designLayerX: 0,
      designLayerY: 0,
      sceneWidth: screenWidth,
      sceneHeight: screenWidth,
      loading: true,
      isFlipping: false,
      isDragging: false,
      isExporting: false,
      isSelectingBackground: false,
      isPinching: false,
      isUpdatingCupPreviewPhotos: false,

      selectedPanelShape: { colors: [] },
      selectedSectionTab: '已選',

      layerVisibility: {
        'image': true,
        'accessories': true,
        'text': true,
      },

      // Line Guides
      lineGuides: [],

      // Image
      customImgPrefix: 'custom_',
      defaultImgWidth: 150,
      defaultImgHeight: 150,
      targetImgAttr: 'brightness',
      
      // Text
      colorChangeTarget: 'color', // color / stroke
      defaultStrokeWidth: 1,
      defaultStrokeColor: 'rgb(255,255,255)',
      defaultFontFamily: '中文娃娃體1',
      defaultFontColor: 'rgb(0,0,0)',
      fontFamilyOptions: FONT_FAMILY_OPTIONS,

      // Eraser
      isErasing: false,
      //erasedPoints: [],
      erasingLines: [],
      erasingShape: { id: '', scale: 1 },
      eraserRadius: 10,
      minEraserHelperRadius: 60,

      // Shapes
      shapes: [],
      panelShapes: [],
      selectedShape: { id: '' },
      lockMoveSelectedShape: false,

      // Filters
      selectedFilter: '', // default filter
      isFiltering: false,
      filteringImg: {
        image: null,
        scale: 1,
        pixelSize: 3,
        levels: -0.05,
      },

      // Background
      backgroundMaterialId: null,
      backgroundImage: null,
      backgroundImageScale: 1,
      backgroundImageX: 0,
      backgroundImageY: 0,

      // Product
      loadedProductImg: null,
      productImgWidth: 0,
      productImgHeight: 0,
      productImgColor: { red: null, green: null, blue: null, alpha: null },
      designAreaShape: { x: 0, y: 0, width: 0, height: 0, radiusX: null, radiusY: null },
      productImgCached: false,
      imageColorCodes: [],
      recommendedColorCodes: [], // based on background image / other images

      // Cups
      leftCupImg: {}, centerCupImg: {}, rightCupImg: {},
      cupPreviewPhotoLink: "",
    });

    // product
    let productLayer, designLayer, productImgNode;

    // eraser
    let eraserNode, eraserGroupNode, erasedImgNode, erasedImgGroupNode;
    let lastSavedErasingLines = [];
    const autoSavePointCount = 100; // auto save during drag

    // gesture (single / two finger moves)
    let lastDist = 0, lastCenter = null, singleFingerLastPoint = null;
    let pointOffsetX = 0, pointOffsetY = 0; // for erasing lines (after scaling)

    // undo / redo
    const appHistory = reactive({
      step: -1,
      states: [],
    })
    const eraserHistory = reactive({
      step: -1,
      states: [],
    })
    const saveStateToHistory = (state: any) => {
      appHistory.states = appHistory.states.slice(0, appHistory.step + 1);
      appHistory.states.push(state);
      appHistory.step += 1;
    }
    const saveStateToEraserHistory = () => {
      const state = {
        action: 'addErasingLines',
        prevErasingLines: [...lastSavedErasingLines],
        nextErasingLines: [...canvas.erasingLines],
      };
      lastSavedErasingLines = [...canvas.erasingLines];
      eraserHistory.states = eraserHistory.states.slice(0, eraserHistory.step + 1);
      eraserHistory.states.push(state);
      eraserHistory.step += 1;
    }

    /**
     * Transformer functions
     */
    const updateTransformer = (forceAttach = false) => {
      checkAttachTransformer(transformer.value, canvas.selectedShape, forceAttach);
    }
    const setCanvasSelectedShape = async (shape: any, lockMoveShape = false, fromPanel = false) => {
      const panelModal: any = document.querySelector('#panel-modal');
      canvas.isErasing = false;
      canvas.isSelectingBackground = false;

      if (canvas.selectedShape.id == shape.id) {
        return; // already is the selected shape)
      }
      canvas.selectedShape = shape;
      canvas.lockMoveSelectedShape = lockMoveShape;
      updateTransformer();
      if (shape.id) {
        if (!shape.materialId && shape.type == 'text') {
          canvas.colorChangeTarget = 'color';
          if (!fromPanel) panelModal.setCurrentBreakpoint(0.3);
        }
      } else if (!fromPanel) {
        const currBreakPoint = await panelModal.getCurrentBreakpoint();
        panelModal.setCurrentBreakpoint(Math.min(currBreakPoint, 0.4));
      }
      if (fromPanel) {
        panelModal.setCurrentBreakpoint(shape.materialId ? 0.4 : 0.6);
      }
      canvas.imageColorCodes = [];
      if (shape.type == 'image' && shape.id.startsWith(canvas.customImgPrefix)) {
        canvas.imageColorCodes = getDominantColorCodes(shape.loadedImg);
      }
    }

    const getCanvasDataURL = (mimeType = "image/png", pixelRatio = 1, extraOptions = {}) => {
      if (canvas.selectedShape.id) clearTransformerSelection(transformer.value); // clear transformer selection
      const targetNode = canvas.isFiltering ? filterStageRef.value.getStage() : (stageRef.value ? designLayer : null);
      const dataUrl = targetNode ? targetNode.toDataURL({ mimeType, pixelRatio, ...extraOptions }) : "";
      if (canvas.selectedShape.id) updateTransformer();
      return dataUrl;
    }

    const getTargetStageDataURL = () => {
      const targetStage = canvas.isFiltering ? filterStageRef.value : stageRef.value;
      const canvasEl = targetStage.getStage().toCanvas({ pixelRatio: OUTPUT_STICKER_WIDTH*2 / canvas.sceneWidth });
      return getTrimmedCanvasDataURL(canvasEl, true);
    }

    /**
     * Canvas Save Functions
     */
    let serverReqFlag: any = null; // auto save canvas server request

    const isCupProducts = () => (props.product.categoryId == 'cups');
    const updateCupPreviewPhotos = async () => {
      // 1. export 3 canvas images (3 sets of x, y, width, height)
      // 2. Make use of helper v-stage to generate preview photos
      // Almost all are the same, only differences are in x
      if (canvas.selectedShape.id) clearTransformerSelection(transformer.value);
      const cupWidth = canvas.sceneWidth / 3;
      const baseConfig = {
        mimeType: "image/png", pixelRatio: OUTPUT_STICKER_WIDTH*2 / cupWidth, width: cupWidth, y: 0, height: canvas.sceneHeight
      };
      // Load the latest cup design images & re-attach the transformer
      for (const targetField of ['leftCupImg', 'centerCupImg', 'rightCupImg']) {
        canvas[targetField]['loadedCupDesignImg'] = await designLayer.toImage({
          ...baseConfig,
          x: targetField == 'leftCupImg' ? 0 : (targetField == 'centerCupImg' ? cupWidth : cupWidth * 2),
        });
      }

      // Generate preview images
      for (const targetField of ['leftCupImg', 'centerCupImg', 'rightCupImg']) {
        const { loadedImg, photoWidth, photoHeight, designRelativeX, designRelativeY, designWidth, designHeight, roundness, } = canvas[targetField];

        // Draw the product image
        const canvasEl: any = document.createElement("canvas");
        const ctx = canvasEl.getContext("2d");
        canvasEl.width = photoWidth;
        canvasEl.height = photoHeight;
        ctx.drawImage(loadedImg, 0, 0, photoWidth, photoHeight, 0, 0, photoWidth, photoHeight);

        // Draw the design image
        const { width: iw, height: ih } = canvas[targetField]['loadedCupDesignImg'];
        const xOffset = +designRelativeX, yOffset = +designRelativeY; // left & top padding
        const a = designWidth / 2; // image width
        const b = roundness; // roundness
        const scaleFactor = iw / (2 * a);

        // draw vertical slices
        for (let X = 0; X < iw; X += 1) {
          const y = b / a * Math.sqrt(a * a - (X - a) * (X - a)); // ellipsis equation
          ctx.drawImage(canvas[targetField]['loadedCupDesignImg'], X*scaleFactor, 0, iw/50, ih, X+xOffset, y+yOffset, 1, designHeight);
        }
        
        // Export the preview image
        await sleep(0.1);
        const dataURL = canvasEl.toDataURL('image/webp');
        canvas[targetField]['previewPhotoLink'] = dataURL;
        canvas[targetField]['canvasEl'] = canvasEl;
      }
      canvas.cupPreviewPhotoLink = await concatCupPreviewPhotos(
        canvas.leftCupImg['canvasEl'], canvas.centerCupImg['canvasEl'], canvas.rightCupImg['canvasEl']
      );
      releaseHTMLCanvases([canvas.leftCupImg['canvasEl'], canvas.centerCupImg['canvasEl'], canvas.rightCupImg['canvasEl']]);
      if (canvas.selectedShape.id) updateTransformer();
    }

    const syncUserDesignData = (previewPhotoBase64) => {
      const { product, sticker } = props;
      const payload = {
        productId: product.id,
        stickerId: sticker.id,
        canvasJson: getDBCanvasData(canvas),
        productImgColor: JSON.stringify(canvas.productImgColor),
        previewPhotoBase64,
      }
      StickerService.upsertProductDesign(payload);
      store.commit('upsertProductDesign', payload);
    }
    const autoSaveCanvas = (timeout = 2000) => {
      if (props.sticker.id) { // editing existing sticker: save to DB directly
        if (canvas.shapes.length == 0) return; // not save canvas if shapes not fully loaded
        if (serverReqFlag) {
          clearTimeout(serverReqFlag);
          serverReqFlag = null;
        }
        serverReqFlag = setTimeout(() => {
          if (isCupProducts()) updateCupPreviewPhotos();
          serverReqFlag = null;
        }, timeout);
      }
    }

    /**
     * Save / load canvas shapes
     */
    const addFilterToProductImgNode = (filters = [Konva.Filters.RGBA]) => {
      if (productImgNode) {
        if (!canvas.productImgCached) {
          productImgNode.cache();
          canvas.productImgCached = true;
        }
        productImgNode.filters(filters);
      }
      autoSaveCanvas();
    }
    const setCanvasLoaded = (timeout = 3000) => {
      if (loadCanvasTimeout) {
        clearTimeout(loadCanvasTimeout);
        loadCanvasTimeout = null;
      }
      canvas.loading = true; // bug fix prevent empty canvas
      loadCanvasTimeout = setTimeout(() => {
        canvas.loading = false;

        // add brighten filters to custom images
        setTimeout(() => {
          if (isCupProducts()) {
            setTimeout(() => {
              updateCupPreviewPhotos();
            }, 500);
          }
          const stage = stageRef.value.getStage();
          eraserNode = stage.findOne('#eraser');
          eraserGroupNode = stage.findOne('#eraserGroup');
          erasedImgNode = stage.findOne('#erasedImg');
          erasedImgGroupNode = stage.findOne('.erasedImgGroup');
          productImgNode = stage.findOne('#productImg');
          productLayer = stage.findOne('.productLayer');
          designLayer = stage.findOne('.designLayer');

          if (canvas.productImgColor.red == null) {
            addFilterToProductImgNode([]);
          } else {
            addFilterToProductImgNode();
          }
          updateTransformer();
        }, 150);

        loadCanvasTimeout = null;
      }, timeout);
    }

    /**
     * Shape Functions
     * addShape: Add shape to specific index
     * removeShape: Remove shape of ID from canvas
     * Recover: shape attrs (x, y, rotation, scale)
     * updateShapeLayerOrder: Move shape to specific order in the layer
     */
    const addShape = (shape: any, shapeIdx: any, panelShapeIdx: any, setShapeSelected = true, saveHistory = false) => {
      if (shape.type == 'text') {
        shape = {
          color: canvas.defaultFontColor,
          fontFamily: canvas.defaultFontFamily,
          strokeWidth: canvas.defaultStrokeWidth,
          strokeColor: canvas.defaultStrokeColor,
          fontStyle: '',
          ...shape,
        }
      }
      const { shapeScale, designLayerX, designLayerY, designLayerScale } = canvas;
      const { designRelativeX, designRelativeY } = props.product;
      if (!('x' in shape) || !isNumber(shape.x)) shape.x = (designRelativeX-designLayerX)/designLayerScale;
      if (!('y' in shape) || !isNumber(shape.y)) shape.y = (designRelativeY-designLayerY)/designLayerScale;
      if (!('scaleX' in shape)) shape.scaleX = shapeScale/designLayerScale;
      if (!('scaleY' in shape)) shape.scaleY = shapeScale/designLayerScale;
      canvas.panelShapes.splice(panelShapeIdx, 0, shape);
      canvas.shapes.splice(shapeIdx, 0, shape);
      if (setShapeSelected) {
        setTimeout(setCanvasSelectedShape, 100, shape);
      }
      if (saveHistory) {
        saveStateToHistory({ action: 'addShape', shape: { ...shape } });
      }
      autoSaveCanvas();
    }

    const removeShape = (shapeId: any, saveHistory = false) => {
      const panelShapeIdx = canvas.panelShapes.findIndex(s => s.id == shapeId);
      const shapeIdx = canvas.shapes.findIndex(s => s.id == shapeId);
      canvas.panelShapes.splice(panelShapeIdx, 1); // panel shape (first element is on the top of layer)
      const removedShapes = canvas.shapes.splice(shapeIdx, 1); // canvas shape (last element is on the top of layer)
      if (saveHistory) {
        saveStateToHistory({
          action: 'removeShape',
          shape: { ...removedShapes[0] },
          panelShapeIdx,
          shapeIdx,
        });
        autoSaveCanvas();
      }
      if (shapeId == canvas.selectedShape.id) setCanvasSelectedShape({ id: '' });
    }
    const recoverShape = (shape: any) => {
      const relatedShapeIdx = canvas.shapes.findIndex((s) => s.id == shape.id);
      canvas.shapes[relatedShapeIdx] = { ...shape };
      if (shape.id == canvas.selectedShape.id) setCanvasSelectedShape(canvas.shapes[relatedShapeIdx]);
    }
    const updateShapeLayerOrder = (oldShapeArrIdx: any, newShapeArrIdx: any, oldPanelShapeArrIdx: any, newPanelShapeArrIdx: any) => {
      arraymove(canvas.shapes, oldShapeArrIdx, newShapeArrIdx);
      arraymove(canvas.panelShapes, oldPanelShapeArrIdx, newPanelShapeArrIdx);
    }
    const updateShapeAttrs = (action: any = '', shapeId: any, attrs: any = {}, saveHistory = true) => {
      const shapeIdx = canvas.shapes.findIndex((s) => s.id == shapeId);
      const prevShape = { ...canvas.shapes[shapeIdx] };
      let someAttrsUpdated = false;
      for (const key in attrs) {
        if (canvas.shapes[shapeIdx][key] != attrs[key]) someAttrsUpdated = true;
        canvas.shapes[shapeIdx][key] = attrs[key];
      }
      if (someAttrsUpdated) {
        if (saveHistory) {
          saveStateToHistory({ action, prevShape, nextShape: { ...canvas.shapes[shapeIdx] } });
        }
        autoSaveCanvas();
      }
    }

    const addCustomImageShape = async (photoDataURL: any, rembgPhotoDataURL: any, saveToHistory = true) => {
      const shapeId = `${canvas.customImgPrefix}${uniqueId()}`;
      const canvasImgShape: any = {
        type: 'image',
        id: shapeId,
        visible: true,
        useRembgPhoto: rembgPhotoDataURL ? true : false,
      };
      await writeFileToDevice(shapeId, photoDataURL, Directory.Data);
      if (rembgPhotoDataURL) await writeFileToDevice(`${shapeId}_rembg`, rembgPhotoDataURL, Directory.Data);

      loadHTMLImg(rembgPhotoDataURL || photoDataURL, (img: any) => {
        canvasImgShape.loadedImg = img;
        setTimeout(() => {
          const scale = Math.min(canvas.defaultImgWidth / img.naturalWidth, canvas.defaultImgHeight / img.naturalHeight);
          canvasImgShape.scaleX = scale;
          canvasImgShape.scaleY = scale;
          addShape(canvasImgShape, canvas.shapes.length, 0, true, saveToHistory);
          setCanvasLoaded(1000);
        }, 100)
      });
    }

    /**
     * Canvas loading
     */
    const addShapeToCanvas = (shape: any, duplicateShape = true) => {
      canvas.shapes.push(shape);
      canvas.panelShapes.unshift(shape);
      if (!('x' in shape) || !isNumber(shape.x)) shape.x = (10-canvas.designLayerX)/canvas.designLayerScale;
      if (!('y' in shape) || !isNumber(shape.y)) shape.y = (10-canvas.designLayerY)/canvas.designLayerScale;

      if (isCupProducts() && duplicateShape) { // duplicate shapes (shown on left & right cups)
        const shapeOnRightCup = {
          ...shape,
          id: `${shape.id.split("::")[0]}::${uniqueId()}`,
          x: shape.x + (canvas.sceneWidth / 3) * 2
        }
        canvas.shapes.push(shapeOnRightCup);
        canvas.panelShapes.unshift(shapeOnRightCup);
      }
    }
    const loadSavedCanvas = async (resetProductDesign = false) => {
      const { product, sticker, sceneWidth, sceneHeight } = props;
      const { photoWidth, photoHeight, designWidth, designHeight, designRelativeX, designRelativeY, designRadiusX, designRadiusY,
              exportAspectRatio, photos, imgColor } = product;
      let { stickerRelativeX, stickerRelativeY, stickerWidth, stickerHeight, } = product;

      const promises = []; // for loading images
      const canvasJson = resetProductDesign ? sticker.canvasJson : (product.canvasJson || sticker.canvasJson);
      const firstTimeEdit = (product.canvasJson ? false : true);
      let productScale = 1;

      if (isCupProducts()) {
        // Special handling for cups (left center & right images)
        for (const photo of photos) {
          const { photoLink, caption, photoWidth, photoHeight, designRelativeX, designRelativeY, designWidth, designHeight, roundness, } = photo;
          if (['leftCupImg', 'centerCupImg', 'rightCupImg'].includes(caption)) {
            promises.push(
              new Promise((resolve, reject) => {
                loadHTMLImg(photoLink, (img: any) => {
                  canvas[caption] = {
                    loadedImg: img, photoWidth, photoHeight, designRelativeX, designRelativeY, designWidth, designHeight, roundness,
                  }
                  resolve(true);
                });
              })
            );
          }
        }
        canvas.sceneHeight = canvas.sceneWidth / exportAspectRatio; // rectangular canvas for cups
        stickerWidth = canvas.sceneWidth / 3;
        stickerHeight = canvas.sceneHeight;
        stickerRelativeX = 0;
        stickerRelativeY = canvas.sceneHeight / 8;
        canvas.shapeScale = Math.min(stickerWidth / sceneWidth, stickerHeight / sceneHeight);
      }
      else if (photoWidth) {
        // Calculate width & height for product canvas (with respect to screen size)
        if (photoWidth >= photoHeight) {
          canvas.sceneWidth = Math.min(canvas.sceneWidth, photoWidth);
          productScale = canvas.sceneWidth / photoWidth;
          canvas.sceneHeight = photoHeight * productScale; // with reference to screen width & height
        } else {
          const canvasSceneHeight = photoWidth >= canvas.sceneWidth ? photoHeight * (canvas.sceneWidth / photoWidth) : photoHeight;
          canvas.sceneHeight = Math.min(window.innerHeight - 200, canvasSceneHeight); // with reference to screen width & height
          productScale = canvas.sceneHeight / photoHeight;
          canvas.sceneWidth = photoWidth * productScale;
        }
        canvas.productImgWidth = canvas.sceneWidth;
        canvas.productImgHeight = canvas.sceneHeight;
        canvas.loadedProductImg = product.canvasImg;

        canvas.shapeScale = Math.min((stickerWidth * productScale) / sceneWidth, (stickerHeight * productScale) / sceneHeight);

        if (designRadiusX) {
          // Dashed circle to show design area
          canvas.designAreaShape = {
            x: designRelativeX * productScale,
            y: designRelativeY * productScale,
            radiusX: designRadiusX * productScale,
            radiusY: (designRadiusY || designRadiusX) * productScale,
            width: 0, height: 0,
          };
        } else {
          // Dashed rectangle to show design area
          canvas.designAreaShape = {
            x: designRelativeX * productScale,
            y: designRelativeY * productScale,
            width: designWidth * productScale,
            height: designHeight * productScale,
            radiusX: null, radiusY: null,
          };
        }
      }
      if (imgColor) canvas.productImgColor = JSON.parse(imgColor);
      
      if (canvasJson) {
        const { shapes, backgroundMaterialId, backgroundImageScale, backgroundImageX, backgroundImageY,
                designLayerX, designLayerY, designLayerScale, } = JSON.parse(canvasJson);
        // Design Layer
        canvas.designLayerScale = designLayerScale || 1;
        canvas.designLayerX = designLayerX || 0;
        canvas.designLayerY = designLayerY || 0;

        // Background
        if (backgroundMaterialId) {
          const relatedMaterial = materials.value.find((m: any) => m.id == backgroundMaterialId);
          if (relatedMaterial) {
            setTimeout(() => {
              const { loadedImg: img } = relatedMaterial;
              canvas.backgroundImage = img;
              canvas.recommendedColorCodes = getDominantColorCodes(img);
              canvas.backgroundImageScale = backgroundImageScale || Math.max(canvas.sceneWidth / img.naturalWidth, canvas.sceneHeight / img.naturalHeight);
              canvas.backgroundImageX = backgroundImageX || 0;
              canvas.backgroundImageY = backgroundImageY || 0;
              canvas.backgroundMaterialId = backgroundMaterialId;
            }, 2000);
          }
        }
        if (shapes) { // add back the shape to canvas
          const { shapeScale, designLayerScale } = canvas;
          for (const shape of shapes) {
            if (firstTimeEdit) {
              shape.scaleX = (shape.scaleX*shapeScale) / designLayerScale;
              shape.scaleY = (shape.scaleY*shapeScale) / designLayerScale;
              shape.x = ((shape.x * shapeScale + (stickerRelativeX * productScale || 0))-designLayerX)/designLayerScale;
              shape.y = ((shape.y * shapeScale + (stickerRelativeY * productScale || 0))-designLayerY)/designLayerScale;
            }
            const existingShape = canvas.shapes.find((s) => s.id == shape.id);
            if (existingShape == null) {
              shape.visible = true;
              if (shape.materialId) {
                const material = materials.value.find((m: any) => m.id == shape.materialId);
                if (material) { // emojis / accessories / clothes / ...
                  let color = {};
                  if (shape.color && material.colors) {
                    color = material.colors.find((c: any) => c.color == shape.color) || {};
                  }
                  addShapeToCanvas({ ...shape, ...material, ...color, id: shape.id }, firstTimeEdit);
                }
              } else {
                if (shape.type == 'image' && shape.id.startsWith(canvas.customImgPrefix)) {
                  try {
                    const parentShapeId = shape.id.split("::")[0];
                    const file = await readFileFromDevice(shape.useRembgPhoto ? `${parentShapeId}_rembg` : parentShapeId);
                    promises.push(
                      new Promise((resolve, reject) => {
                        loadHTMLImg(`data:image/webp;base64,${file.data}`, (img: any) => {
                          shape.loadedImg = img;
                          resolve(true);
                        })
                      })
                    );
                  } catch (e) {
                    console.log(e.message);
                    console.error(e); // image not found
                  }
                }
                addShapeToCanvas(shape, firstTimeEdit);
              }
            }
          }
        }
      }
      Promise.all(promises).then(res => {
        setCanvasLoaded(2000);
      });
    }

    /**
     * Canvas / material event listeners
     */
    let pinched = false;
    const resetEraseImage = () => {
      lastDist = 0;
      lastCenter = null;
      pointOffsetX = 0;
      pointOffsetY = 0;

      canvas.erasingLines = [];
      canvas.erasingShape = { id: '', scale: 1 };
      canvas.isErasing = false;
      lastSavedErasingLines = [];
      eraserHistory.step = -1;
      eraserHistory.states = [];
      erasedImgGroupNode.scaleX(1);
      erasedImgGroupNode.scaleY(1);
      erasedImgGroupNode.position({ x: undefined, y: undefined });
      if (serverReqFlag) autoSaveCanvas();
    }
    let lastSelectShapeTime = 0;
    const handleStageMouseDown = async (e: any) => {
      if (isPinching(e)) return;
      if (canvas.isErasing) {
        if (isPinching(e)) return; // scaling the images
        try {
          // Eraser mode
          const strokeWidth = (canvas.eraserRadius * 2) / canvas.erasingShape.scale;
          if (e.target.getParent() && e.target.getParent().getId() == 'eraserGroup') {
            canvas.erasingLines = [...canvas.erasingLines, { points: [], strokeWidth }];
          } else {
            const { x, y } = stageRef.value.getStage().getPointerPosition();
            eraserGroupNode.setAttrs({ x, y });
            canvas.erasingLines = [...canvas.erasingLines, { points: [], strokeWidth }];
            saveStateToEraserHistory();
          }
        } catch (e) { return; }
      } else {
        if (canvas.isPinching) return;
        // clicked on stage - clear selection
        try {
          if (e.target === e.target.getStage() || e.target.getLayer().name() != 'designLayer') {
            await sleep(0.05);
            if (!canvas.isPinching) setCanvasSelectedShape({ id: '' });
            return;
          }
        } catch (e) { return; }

        const clickedOnTransformer = (e.target.getParent().className === 'Transformer');
        if (clickedOnTransformer) return;

        if (canvas.lockMoveSelectedShape) {
          if (e.target.getId() != canvas.selectedShape.id) {
            setCanvasSelectedShape({ id: '' }); // release lock if click empty area
            return;
          }
          canvas.isDragging = true; // if mouse up then it will be set to false
          await sleep(0.15);
          if (!canvas.isDragging) {
            setCanvasSelectedShape({ id: '' });
          }
          return;
        }
        
        await sleep(0.05);
        if (!canvas.isPinching) {
          const id = e.target.getId();
          const shape = canvas.shapes.find((s) => s.id == id);
          setCanvasSelectedShape(shape || { id: '' }, false);
          lastSelectShapeTime = new Date().valueOf();
        }
      }
    }
    const handleStageMouseMove = (e: any) => {
      canvas.isDragging = true;
      if (e && e.evt) {
        const [touch1, touch2] = e.evt.touches;
        if (touch1 && touch2) {
          // Two-finger move
          canvas.isPinching = true;
          const p1 = { x: touch1.clientX, y: touch1.clientY };
          const p2 = { x: touch2.clientX, y: touch2.clientY };
          const dist = getDistance(p1, p2);
          if (!lastDist) lastDist = dist;

          if (canvas.isSelectingBackground) {
            const newScale = (canvas.backgroundImageScale * dist) / lastDist;
            if (!lastCenter) {
              lastCenter = getCenter(p1, p2);
              return;
            }
            const newCenter = getCenter(p1, p2);
            const pointTo = {
              x: (newCenter.x - canvas.backgroundImageX) / newScale,
              y: (newCenter.y - canvas.backgroundImageY) / newScale,
            };
            canvas.backgroundImageScale = newScale;
            canvas.backgroundImageX = newCenter.x - pointTo.x * newScale + (newCenter.x - lastCenter.x);
            canvas.backgroundImageY = newCenter.y - pointTo.y * newScale + (newCenter.y - lastCenter.y);
            lastDist = dist;
            lastCenter = newCenter;
            pinched = true;
          }
          else {
            if (new Date().valueOf() - lastSelectShapeTime < 500) {
              // for better UX
              canvas.selectedShape = { id: '' };
              transformer.value.getNode().nodes([]);
            }
            const stage = stageRef.value.getStage();
            const targetShape: any = canvas.isErasing ? canvas.erasingShape : canvas.selectedShape;
            const targetNode = canvas.isErasing ? erasedImgGroupNode :
                              (canvas.selectedShape.id ? stage.findOne(`#${canvas.selectedShape.id}`) : stage.findOne('#designShapes'));
            if (targetNode) {
              const scale = (targetNode.scaleX() * dist) / lastDist;
              targetNode.scaleX(scale);
              targetNode.scaleY(scale);
              targetShape.scale = scale;

              if (!lastCenter) {
                lastCenter = getCenter(p1, p2);
                return;
              }
              const newCenter = getCenter(p1, p2);
              const pointTo = {
                x: (newCenter.x - targetNode.x()) / targetNode.scaleX(),
                y: (newCenter.y - targetNode.y()) / targetNode.scaleX(),
              };
              const newX = newCenter.x - pointTo.x * scale + (newCenter.x - lastCenter.x);
              const newY = newCenter.y - pointTo.y * scale + (newCenter.y - lastCenter.y);
              targetNode.position({ x: newX, y: newY });

              pointOffsetX = 0 - (newX / scale);
              pointOffsetY = 0 - (newY / scale);

              lastDist = dist;
              lastCenter = newCenter;
              pinched = true;
            }
          }
        } else if (touch1 && !canvas.selectedShape.id) {
          // Single-finger move
          const p1 = { x: touch1.clientX, y: touch1.clientY };
          if (!singleFingerLastPoint) {
            singleFingerLastPoint = p1;
            return;
          }
          canvas.backgroundImageX = canvas.backgroundImageX - (singleFingerLastPoint.x-p1.x);
          canvas.backgroundImageY = canvas.backgroundImageY - (singleFingerLastPoint.y-p1.y);
          singleFingerLastPoint = p1;
        }
      }
    }
    const handleStageTouchEnd = (e: any) => {
      lastDist = 0;
      lastCenter = null;
      singleFingerLastPoint = null;
      canvas.isDragging = false;
      canvas.isPinching = false;
      if (pinched) {
        if (canvas.selectedShape.id) {
          const targetNode = stageRef.value.getStage().findOne(`#${canvas.selectedShape.id}`);
          updateShapeAttrs('transform', canvas.selectedShape.id, {
            x: targetNode.x(),
            y: targetNode.y(),
            //rotation: e.target.rotation(),
            scaleX: targetNode.scaleX(),
            scaleY: targetNode.scaleY(),
          });
        } else {
          if (!canvas.isSelectingBackground) {
            const designLayerNode = stageRef.value.getStage().findOne('#designShapes');
            const newScale = designLayerNode.scaleX();
            canvas.eraserRadius = canvas.eraserRadius / (newScale / canvas.designLayerScale);
            canvas.designLayerX = designLayerNode.x();
            canvas.designLayerY = designLayerNode.y();
            canvas.designLayerScale = newScale;
            resetEraseImage();
          }
          autoSaveCanvas(3000);
        }
        pinched = false;
      }
    }
    const handleEraserDragMove = (e: any) => {
      if (canvas.isErasing) {
        if (isPinching(e)) return;
        const { x, y } = e.target.attrs;
        const { scale } = canvas.erasingShape;
        const lastLine = canvas.erasingLines[canvas.erasingLines.length - 1];
        lastLine.points = lastLine.points.concat([x/scale+pointOffsetX, y/scale+pointOffsetY]);
        canvas.erasingLines.splice(canvas.erasingLines.length - 1, 1, lastLine);
        if (lastLine.points.length > autoSavePointCount) {
          saveStateToEraserHistory();
          canvas.erasingLines = [...canvas.erasingLines, { points: [x/scale+pointOffsetX, y/scale+pointOffsetY], strokeWidth: (canvas.eraserRadius * 2) / canvas.erasingShape.scale }];
        }
      }
    }
    const handleEraserDragEnd = (e: any) => {
      if (canvas.isErasing) saveStateToEraserHistory();
    }

    const handleTransformEnd = (e: any) => {
      updateShapeAttrs('transform', canvas.selectedShape.id, {
        x: e.target.x(),
        y: e.target.y(),
        rotation: e.target.rotation(),
        scaleX: e.target.scaleX(),
        scaleY: e.target.scaleY(),
      });
      canvas.lineGuides = [];
    }
    const handleTransform = (e: any) => {
      refreshLineGuides(e.target, [e.target], stageRef, canvas);
    }
    const handleTrDragMove = (e: any) => {
      onTrDragMove(e, stageRef, canvas, transformer);
    };
    const handleTrDragEnd = (e: any) => {
      const tr = transformer.value.getNode();
      tr.nodes().forEach((shape: any) => {
        const absPos = shape.getAbsolutePosition();
        updateShapeAttrs('move', shape.id(), {
          x: shape.x(),
          y: shape.y(),
        });
      });
      canvas.lineGuides = [];
    };

    /**
     * INIT
     */
    const loadMaterialImages = async (materials: any) => {
      canvas.loading = true;
      await initMaterials(materials);
      loadSavedCanvas();
    }
    onMounted(() => {
      if (canvas.loading == true) loadMaterialImages(materials.value);
    });
    onBeforeUnmount(() => {
      clearKonvaDOMCanvases(filterStageRef.value.getStage());
      clearKonvaDOMCanvases(stageRef.value.getStage());
      resetCustomShapeFilterState(canvas.shapes);
      canvas.productImgCached = false;
    });


    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      checkmark, close, chevronBack, chevronForward, logoWhatsapp, shareSocialOutline, downloadOutline,
      swapHorizontal, chevronUp, chevronDown, eyeOutline, eyeOffOutline, 
      arrowUndoOutline, arrowRedoOutline, cameraOutline, colorWandOutline, createOutline, add, text, scan,
      ellipsisVertical, ellipsisHorizontal, folderOutline, copyOutline, duplicateOutline, trashOutline,
      remove, arrowForward, arrowBack, refresh, crop, removeCircleOutline,

      // variables
      dummy, settings,
      materials, materialCategories,
      appHistory, eraserHistory,

      // popover
      popoverState, popoverEvent,
      isColorPopoverOpenRef, colorPopoverEvent,

      // canvas
      canvas,
      stageRef, transformer,
      filterStageRef, photoFilters,

      // event listeners
      handleStageMouseDown, handleStageMouseMove, handleStageTouchEnd,
      handleEraserDragMove, handleEraserDragEnd,
      handleTransform, handleTransformEnd,
      handleTrDragMove, handleTrDragEnd,

      // methods
      t, getLocalizedStr, isNumber,
      getCanvasDataURL,
      setCanvasSelectedShape,
      getCorner, getClientRect,
      removeShape,
      openImageModal,
      navigateMaterialCategories,

      isSelectingCustomImage: () => (canvas.selectedShape.id || '').toString().startsWith(canvas.customImgPrefix),
      isSelectingCustomText: () => (canvas.selectedShape['type'] == 'text' && !canvas.selectedShape['materialId']),

      onModalPresent: () => { // init color picker after modal presents
        // Product Color Picker
        if (productColorPicker == null) {
          productColorPicker = new ColorPicker({
            background: '#454545',
            el: '#product-color-picker',
            width: 208
          });
          productColorPicker.onChange(() => {
            if (productColorPicker.isChoosing) {
              const { r, g, b } = productColorPicker.getRGB();
              const red = r*255, green = g*255, blue = b*255;
              canvas.productImgColor = { red, green, blue, alpha: 0.5 };
              addFilterToProductImgNode();
            }
          })
        }
        // Text Color Picker
        if (textColorPicker == null) {
          textColorPicker = new ColorPicker({
            background: '#454545',
            el: '#text-color-picker',
            width: 208
          });
          textColorPicker.onChange(() => {
            if (textColorPicker.isChoosing) {
              const newRgb = textColorPicker.getRGB();
              for (const key in newRgb) newRgb[key] = (Math.round(newRgb[key] * 1000) / 1000) * 255;
              updateShapeAttrs('changeColor', canvas.selectedShape.id, {
                [canvas.colorChangeTarget]: `rgb(${newRgb.r},${newRgb.g},${newRgb.b})`,
              });
            }
          });
        }
      },

      // Erase images
      startErasingImage: async () => {
        if (serverReqFlag) clearTimeout(serverReqFlag); // stop auto update
        canvas.erasingShape = canvas.shapes.find(s => s.id == canvas.selectedShape.id);
        canvas.erasingShape.scale = 1; // default scale (relative);
        setCanvasSelectedShape({ id: '' });
        canvas.isErasing = true;
        setModalBreakpoint(0.3);
        lastSavedErasingLines = [{ points: [eraserNode.x(), eraserNode.y()], strokeWidth: canvas.eraserRadius * 2 / canvas.erasingShape.scale }];
      },
      resetEraseImage,
      completeEraseImage: async (erasingShape: any) => {
        const { id, useRembgPhoto, loadedImg, scale, scaleX, scaleY, x: imgX, y: imgY } = erasingShape;
        const { x: groupX, y: groupY } = erasedImgGroupNode.position();
        const x = groupX + (imgX * scale);
        const y = groupY + (imgY * scale);
        const finalScaleX = scaleX * scale, finalScaleY = scaleY * scale;
        const options = { pixelRatio: 1 / finalScaleX, x, y,  width: loadedImg.naturalWidth * finalScaleX, height: loadedImg.naturalHeight * finalScaleY };
        const newImgDataURL = getTrimmedCanvasDataURL(erasedImgGroupNode.toCanvas(options), true);

        await writeFileToDevice(useRembgPhoto ? `${id}_rembg` : id, newImgDataURL, Directory.Data);
        loadHTMLImg(newImgDataURL, async (img: any) => {
          updateShapeAttrs('changeCustomImgSrc', id, { loadedImg: img }, true);
          resetEraseImage();
          setCanvasLoaded(100);
          autoSaveCanvas(); // sync latest image to server
        })
      },

      // add material to canvas
      addMaterialToCanvas: (material: any, color: any, ev: any) => {
        if (material.category == 'background') {
          setCanvasSelectedShape({ id: '' });
          const { id, loadedImg: img } = material;
          canvas.backgroundMaterialId = id;
          canvas.backgroundImage = img; // update the background image on canvas
          canvas.recommendedColorCodes = getDominantColorCodes(img);
          canvas.backgroundImageScale = Math.max(canvas.sceneWidth / img.naturalWidth, canvas.sceneHeight / img.naturalHeight);
          canvas.backgroundImageX = 0;
          canvas.backgroundImageY = 0;
          canvas.isSelectingBackground = true;
        } else {
          if (material.colors && material.colors.length > 0 && color == null) {
            scrollToRefElement(ev.target, 'auto');
            canvas.selectedPanelShape = material;
            isColorPopoverOpenRef.value = true;
            colorPopoverEvent.value = ev;
          } else {
            const canvasShape = { ...material, ...color, materialId: material.id, id: uniqueId(), visible: true };
            addShape(canvasShape, canvas.shapes.length, 0, true, true);
          }
        }
        autoSaveCanvas();
      },

      // Prompt
      openTextPrompt: async (textShape: any = {}) => {
        const alert = await alertController.create({
          header: textShape.id ? t('editText') : t('addText'),
          inputs: [
            {
              name: 'text',
              type: 'textarea',
              placeholder: t('enterTextHere'),
              value: textShape.text || "",
            },
          ],
          buttons: [
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: t('confirm'),
              handler: (value) => {
                if (value.text) {
                  if (textShape.id) { // edit text
                    if (textShape.text != value.text) {
                      updateShapeAttrs('updateText', textShape.id, { text: value.text });
                    }
                  } else { // new text
                    const newTextShape = { id: uniqueId(), type: 'text', text: value.text };
                    addShape(newTextShape, canvas.shapes.length, 0, true, true);
                  }
                  return true;
                }
                return false; // not closing the alert
              }
            }
          ]
        });
        await alert.present();
      },

      // Modal
      leavePage: async (done = false) => {
        let data = null;
        if (done) {
          const loading = await loadingController.create({});
          await loading.present();
          try {
            const mimeType = 'image/webp';
            canvas.isExporting = true;
            if (canvas.selectedShape.id) clearTransformerSelection(transformer.value);
            await sleep(0.05);

            let designPhotoExportOpts: any = { mimeType, pixelRatio: 2000 / canvas.sceneWidth };
            if (isCupProducts()) {
              await updateCupPreviewPhotos();
            } else {
              const { radiusX, radiusY } = canvas.designAreaShape;
              let { x, y, width, height, } = canvas.designAreaShape;
              if (radiusX) {
                x = x - radiusX; // from center point to top-left
                y = y - radiusY; // from center point to top-left
                width = radiusX * 2; // diameter
                height = radiusY * 2; // diameter
              }
              designPhotoExportOpts = { x, y, width, height };
            }
            const previewPhotoBase64 = isCupProducts() ? canvas.cupPreviewPhotoLink : getTargetStageDataURL();
            data = {
              designPhotoBase64: designLayer.toDataURL(designPhotoExportOpts),
              productPhotoBase64: productImgNode ? productImgNode.toDataURL({ mimeType }) : "",
              previewPhotoBase64,
              canvasJson: getDBCanvasData(canvas),
              productImgColor: JSON.stringify(canvas.productImgColor),

              // for cup only
              leftPreviewPhotoLink: canvas.leftCupImg['previewPhotoLink'],
              centerPreviewPhotoLink: canvas.centerCupImg['previewPhotoLink'],
              rightPreviewPhotoLink: canvas.rightCupImg['previewPhotoLink'],
            };
            syncUserDesignData(previewPhotoBase64);
            canvas.isExporting = false;
          } catch (e) {
            data = {};
          } finally {
            loading.dismiss();
          }
        }
        await presentPrompt(t(done ? "confirmChange" : "confirmLeave"), done ? `<img src="${data.previewPhotoBase64}" />` :  t("willNotSaveChanges"), async () => {
          if (textColorPicker) textColorPicker.remove();
          if (productColorPicker) productColorPicker.remove();
          canvas.productImgColor = { red: null, green: null, blue: null, alpha: null };

          await modalController.dismiss(); // shape selection panel
          await modalController.dismiss(data); // DIY modal
        }, 'product-preview-alert');
      },
      openNewCustomImagePrompt: async () => {
        const photo: Photo = await takePhoto();
        if (photo) {
          // get photo successfully
          const modal = await modalController.create({
            component: StickerPreQuestionModal,
            componentProps: { stickerId: props.sticker.id, petPhoto: photo.base64Data, mimeType: photo.mimeType },
          });
          modal.onDidDismiss().then(async ({ data }) => {
            if (data) {
              const { customPhoto, rembgCustomPhoto } = data;
              addCustomImageShape(customPhoto, rembgCustomPhoto);
            }
          });
          return modal.present();
        }
      },
      openStickerPreQuestionModal: async (stickerId: any, shapeId: any) => {
        const file = await readFileFromDevice(shapeId);
        const petPhoto = `data:image/webp;base64,${file.data}`;
        const modal = await modalController.create({
          component: StickerPreQuestionModal,
          componentProps: { stickerId, petPhoto, mimeType: 'image/webp' },
        });
        modal.onDidDismiss().then(async ({ data }) => {
          if (data) {
            const { customPhoto, rembgCustomPhoto } = data;
            await writeFileToDevice(shapeId, customPhoto, Directory.Data);
            if (rembgCustomPhoto) await writeFileToDevice(`${shapeId}_rembg`, rembgCustomPhoto, Directory.Data);
            loadHTMLImg(rembgCustomPhoto || customPhoto, (img: any) => {
              updateShapeAttrs('changeCustomImgSrc', shapeId, { loadedImg: img, useRembgPhoto: rembgCustomPhoto ? true : false }, true);
              setCanvasLoaded(100);
            });
          }
        });
        return modal.present();
      },
        
      /**
       * UNDO / REDO (app history)
       */
      undo: (appHistory: any) => {
        if (appHistory.step === -1) return;

        const currState = appHistory.states[appHistory.step--];

        if (currState.action == 'updateShapeLayerOrder') {
          const { currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx } = currState;
          updateShapeLayerOrder(newShapeArrIdx, currShapeArrIdx, newPanelShapeArrIdx, currPanelShapeArrIdx);
        }
        else if (currState.action == 'addShape') {
          removeShape(currState.shape.id);
        }
        else if (currState.action == 'removeShape') {
          const { shape, panelShapeIdx, shapeIdx } = currState;
          addShape(shape, shapeIdx, panelShapeIdx);
        }
        else if (currState.action == 'addErasingLines') {
          const { prevErasingLines } = currState;
          canvas.erasingLines = [...prevErasingLines];
          lastSavedErasingLines = [...canvas.erasingLines];
          if (canvas.erasingLines.length > 0) {
            const [x, y] = canvas.erasingLines[canvas.erasingLines.length-1].points.slice(-2);
            if (x != null && y != null) eraserGroupNode.absolutePosition({ x, y });
          }
        }
        else if (currState.action == 'changeCustomImgSrc') {
          const { useRembgPhoto, id, loadedImg } = currState.prevShape;
          writeFileToDevice(useRembgPhoto ? `${id}_rembg` : id, loadedImg.src, Directory.Data);
          recoverShape(currState.prevShape);
        }
        else {
          recoverShape(currState.prevShape);
        }
        if (isCupProducts()) updateCupPreviewPhotos(); // sync cup preview photos
      },
      redo: (appHistory: any) => {
        if (appHistory.step === appHistory.states.length-1) return;

        const currState = appHistory.states[++appHistory.step];

        if (currState.action == 'updateShapeLayerOrder') {
          const { currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx } = currState;
          updateShapeLayerOrder(currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx);
        }
        else if (currState.action == 'addShape') {
          addShape(currState.shape, canvas.shapes.length, 0);
        }
        else if (currState.action == 'removeShape') {
          removeShape(currState.shape.id);
        }
        else if (currState.action == 'addErasingLines') {
          const { nextErasingLines } = currState;
          canvas.erasingLines = [...nextErasingLines];
          lastSavedErasingLines = [...canvas.erasingLines];
          if (canvas.erasingLines.length > 0) {
            const [x, y] = canvas.erasingLines[canvas.erasingLines.length-1].points.slice(-2);
            if (x != null && y != null) eraserGroupNode.absolutePosition({ x, y });
          }
        }
        else if (currState.action == 'changeCustomImgSrc') {
          const { useRembgPhoto, id, loadedImg } = currState.nextShape;
          writeFileToDevice(useRembgPhoto ? `${id}_rembg` : id, loadedImg.src, Directory.Data);
          recoverShape(currState.nextShape);
        }
        else {
          recoverShape(currState.nextShape);
        }
        if (isCupProducts()) updateCupPreviewPhotos(); // sync cup preview photos
      },
    
      // shape actions
      getShapeDataURL,
      toggleFontStyle: (style: any) => {
        if (canvas.selectedShape['type'] == 'text') {
          let newFontStyle = canvas.selectedShape['fontStyle'] || '';
          if (newFontStyle.includes(style)) {
            newFontStyle = newFontStyle.replace(style, '');
          } else {
            newFontStyle = `${newFontStyle} ${style}`;
          }
          canvas.selectedShape['fontStyle'] = newFontStyle.trim();
        }
      },
      duplicateSelectedShape: () => {
        const newShape = {
          ...canvas.selectedShape,
          x: canvas.selectedShape['x'] + 10,
          y: canvas.selectedShape['y'] + 10,
          id: uniqueId(),
          visible: true,
        };
        addShape(newShape, canvas.shapes.length, 0, true, true);
      },
      flipSelectedShape: () => {
        canvas.isFlipping = true;
        const targetNode = stageRef.value.getStage().findOne(`#${canvas.selectedShape.id}`);
        const shapeWidth = targetNode.width() * Math.abs(targetNode.scaleX());
        const newX = -targetNode.scaleX() < 0 ? targetNode.x() + shapeWidth : targetNode.x() - shapeWidth;
        targetNode.to({
          scaleX: -targetNode.scaleX(), // flip the shape on canvas
          x: newX,
          onFinish: () => canvas.isFlipping = false,
        });
        updateShapeAttrs('flip', canvas.selectedShape.id, { scaleX: -targetNode.scaleX(), x: newX });
      },
      moveSelectedShape: (direction: any) => { // update order of selected shape in the layer
        if (canvas.selectedShape.id) {
          const currShapeArrIdx = canvas.shapes.findIndex(s => s.id == canvas.selectedShape.id);
          const currPanelShapeArrIdx = canvas.panelShapes.findIndex(s => s.id == canvas.selectedShape.id);
          const lastArrIdx = canvas.shapes.length-1;
          let newShapeArrIdx, newPanelShapeArrIdx;
          if (direction == 'top') {
            newShapeArrIdx = lastArrIdx;
            newPanelShapeArrIdx = 0;
          }
          else if (direction == 'bottom') {
            newShapeArrIdx = 0;
            newPanelShapeArrIdx = lastArrIdx;
          }
          else if (direction == 'up') {
            newShapeArrIdx = Math.min(currShapeArrIdx+1, lastArrIdx);
            newPanelShapeArrIdx = Math.max(currPanelShapeArrIdx-1, 0);
          }
          else if (direction == 'down') {
            newShapeArrIdx = Math.max(currShapeArrIdx-1, 0);
            newPanelShapeArrIdx = Math.min(currPanelShapeArrIdx+1, lastArrIdx);
          }
          updateShapeLayerOrder(currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx);

          saveStateToHistory({
            action: 'updateShapeLayerOrder',
            shapeId: canvas.selectedShape.id,
            currShapeArrIdx,
            currPanelShapeArrIdx,
            newShapeArrIdx,
            newPanelShapeArrIdx,
          });
        }
      },
      setShapeVisible: (shape: any, visible: any) => {
        updateShapeAttrs('setShapeVisible', shape.id, { visible });
      },

      // Image Filters,
      enterFilterMode: () => {
        if (serverReqFlag) clearTimeout(serverReqFlag); // stop auto update
        const canvasOutputBase64 = getCanvasDataURL("image/webp", OUTPUT_STICKER_WIDTH*2 / canvas.sceneWidth);
        loadHTMLImg(canvasOutputBase64, (img: any) => {
          canvas.filteringImg.image = img;
          setTimeout(() => {
            canvas.filteringImg.scale = (Math.min(canvas.sceneWidth / img.naturalWidth, canvas.sceneHeight / img.naturalHeight));
          }, 100);
        });
        canvas.isFiltering = true;
        setModalBreakpoint(0.3); 
      },
      leaveFilterMode: () => {
        canvas.isFiltering = false;
        if (serverReqFlag) autoSaveCanvas();
      },
      applyFilter: (filter: any) => {
        canvas.selectedFilter = filter;
        const targetNode = filterStageRef.value.getStage().findOne(`#filteringImg`);
        if (targetNode) {
          let filters = [];
          if (filter == photoFilters.pixelate) filters = [Konva.Filters.Pixelate];
          else if (filter == photoFilters.posterize) filters = [Konva.Filters.Posterize];
          targetNode.filters(filters);
          targetNode.cache({ pixelRatio: Math.min(OUTPUT_STICKER_WIDTH*2 / canvas.filteringImg.image.naturalWidth, 1) });
        }
      },

      onMaterialCategoryChanged: () => {
        canvas.selectedPanelShape = { colors: [] };
      },
      clearCanvasBackground: () => {
        canvas.recommendedColorCodes = [];
        canvas.backgroundImage = null;
        canvas.backgroundMaterialId = null;
        canvas.backgroundImageScale = 1;
        canvas.backgroundImageX = 0;
        canvas.backgroundImageY = 0;
        canvas.isSelectingBackground = false;
        autoSaveCanvas();
      },

      addFiltersToSelectedShape: () => {
        const shape: any = canvas.selectedShape;
        const pixelRatio = Math.min(OUTPUT_STICKER_WIDTH*2 / shape.loadedImg.naturalWidth, 1);
        addFiltersToShape(stageRef.value, shape, [Konva.Filters.Brighten, SoftImageEdge], pixelRatio);
      },

      changeTextColor: (color: any) => {
        const [red, green, blue] = color.replace(/[^\d,]/g, '').split(',');
        updateShapeAttrs('changeColor', canvas.selectedShape.id, {
          [canvas.colorChangeTarget]: `rgb(${red},${green},${blue})`,
        });
      },

      // Product DIY (color)
      changeProductColor: (color: any) => {
        if (color == null) {
          canvas.productImgColor = { red: null, green: null, blue: null, alpha: null };
          addFilterToProductImgNode([]);
        } else {
          const [red, green, blue] = color.replace(/[^\d,]/g, '').split(',');
          canvas.productImgColor = { red, green, blue, alpha: 0.5 };
          addFilterToProductImgNode();
        }
      },
      setPopoverOpen: (popoverKey: any, state: boolean, ev: any) => {
        popoverEvent.value = ev; 
        popoverState[popoverKey] = state;
      },
      getCenteredGridlinePoint: (target: any) => {
        const { x, y, width, height, radiusX, radiusY } = canvas.designAreaShape;
        return target == 'x' ? (radiusX ? x : (x + width / 2)) : (radiusY ? y : (y + height / 2));
      },
      isCupProducts,

      // Reset Product Design (using Sticker Design)
      resetProductDesign: async () => {
        await presentPrompt(t("confirmResetDesign"), t("cannotBeUndone"), () => {
          loadSavedCanvas(true);
        });
      }
      
    }
  }
})
