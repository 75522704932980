<template>
  <!-- Header -->
  <page-header :showCloseModal="true" :title="t('createDIYProducts')" slotType="toolbar">
    <!-- Product Category Filter -->
    <ion-toolbar>
      <ion-segment mode="md" v-model="selectedCategoryId" scrollable>
        <ion-segment-button value="all">
          <ion-label>{{ t('all') }}</ion-label>
        </ion-segment-button>
        <ion-segment-button v-for="category in productCategories" :key="category.id" :value="category.id" layout="icon-start">
          <ion-label>{{ getLocalizedStr(category, 'title', 'titleEn') }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </page-header>

  <ion-content class="ion-text-center">
    <ion-grid fixed>

      <!-- Loading Data -->
      <div class="spin ion-text-center" v-if="canvas.loading">
        <p><img style="width: 100px" src="@/assets/logo.png" /></p>
        
        <!--<ion-spinner></ion-spinner>-->
        <ion-progress-bar :value="canvas.loadingPercent"></ion-progress-bar>
        <p>{{ (canvas.loadingPercent * 100).toFixed(0) }}%</p>
        <h3 class="ion-margin-vertical">{{ t('generatingProductPreviewPhotos') }}</h3>
      </div>

      <!-- Data loaded -->
      <ion-row class="ion-justify-content-center" v-else>
        <ion-col v-for="product in products" :key="product.id" :size="(product.photoWidth - product.photoHeight) > product.photoHeight ? 12 : 6"
                v-show="selectedCategoryId == 'all' || selectedCategoryId == product.categoryId">
          <ion-card style="border-top-right-radius: 4px; border-top-left-radius: 4px; padding-top: 8px">
            <ion-badge class="top-left-badge" color="medium" v-if="product.size">{{ product.size }}</ion-badge>
            <ion-badge class="top-right-badge" color="danger">HK${{ product.sellingPrice?.toLocaleString() }}</ion-badge>

            <div class="ion-text-center" style="padding: 5px 5px 0">
              <ion-spinner v-if="!product.loadedPreviewPhoto"></ion-spinner>
              <div :style="{ 'background': product.categoryId == 'throwpillows' ? 'lightgray' : null }" v-else>
                <div v-if="product.categoryId == 'cups' && product.leftPreviewPhotoLink">
                  <image-slides :imageLinks="[product.leftPreviewPhotoLink, product.centerPreviewPhotoLink, product.rightPreviewPhotoLink]"
                                :minSlidesPerView="1.5" size="small" :hideEyeIcon="true" :defaultCaption="getLocalizedStr(product, 'title', 'titleEn')"></image-slides>
                      <!--
                  <img class="cup-preview-img" :src="product.leftPreviewPhotoLink"
                      @click="openImageModal(product.leftPreviewPhotoLink, `${getLocalizedStr(product, 'title', 'titleEn')} ${product.size}`)" />
                  <img class="cup-preview-img" :src="product.centerPreviewPhotoLink"
                      @click="openImageModal(product.centerPreviewPhotoLink, `${getLocalizedStr(product, 'title', 'titleEn')} ${product.size}`)" />
                  <img class="cup-preview-img" :src="product.rightPreviewPhotoLink"
                      @click="openImageModal(product.rightPreviewPhotoLink, `${getLocalizedStr(product, 'title', 'titleEn')} ${product.size}`)" />
                      -->
                </div>
                <img style="max-height: 150px" :src="product.previewPhotoLink"
                    @click="openImageModal(product.previewPhotoLink, `${getLocalizedStr(product, 'title', 'titleEn')} ${product.size}`)" v-else />
              </div>
            </div>
            <ion-card-content style="padding: 8px">
              <ion-item class="quantity-input-item ion-no-padding" lines="none">
                <ion-button class="qty-action-btn" @click.stop='decrementCartItemQty(product)'><ion-icon slot="icon-only" :icon="remove"></ion-icon></ion-button>
                <input class="quantity-input" type="number" min="0" max="999" step="1" v-model="product.requiredQty" @blur.stop='onFinishInputCartItemQty($event, product)' placeholder="0" />
                <ion-button class="qty-action-btn" @click.stop='incrementCartItemQty(product)'><ion-icon slot="icon-only" :icon="add"></ion-icon></ion-button>
              </ion-item>
              <p><b>{{ getLocalizedStr(product, 'title', 'titleEn') }}</b></p>
              <ion-button fill="outline" @click="openDIYProductDesignModal(product, sticker)" class="ion-no-margin" color="secondary" size="small">
                <ion-icon slot="start" :icon="pencil"></ion-icon>
                {{ t('editDesign') }}
              </ion-button>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <div style="visibility: hidden">
        <v-stage
          ref="stageRef"
          :config="{ width: canvas.sceneWidth, height: canvas.sceneHeight }"
        >
          <v-layer>
            <v-image :config="{
                id: 'productImg',
                image: canvas.loadedProductImg,
                width: canvas.productImgWidth,
                height: canvas.productImgHeight,
              }"
              v-if="canvas.loadedProductImg"
            ></v-image>
            <v-image
              :config="{
                id: 'stickerImg',
                image: canvas.loadedStickerImg,
                x: canvas.stickerImgX,
                y: canvas.stickerImgY,
                width: canvas.stickerImgWidth,
                height: canvas.stickerImgHeight,
                blurRadius: canvas.blurRadius,
                ...canvas.extraStickerImgOps,
              }"
            ></v-image>
          </v-layer>
        </v-stage>
      </div>
    </ion-grid>
  </ion-content>

  <!--
   Footer: Checkout button & free shipping progress bar
  -->
  <ion-footer v-show="!canvas.loading">
    <div v-show="getCartTotal() > 0">
      <ion-item :color="getFreeShippingProgress() < 1 ? 'secondary' : 'dark'" style="border-top-left-radius: 10px; border-top-right-radius: 10px">
        <ion-label style="text-align: center; margin: 5px 0px">
          <h3>
            <b v-show="getFreeShippingProgress() < 1">
              {{ t('addMoreOrderAmount') }}HK$ {{ (settings.freeShippingMinOrderTotal - getCartTotal()).toLocaleString() }} {{ t('toGetFreeShippingDiscount') }}
            </b>
            <b v-show="getFreeShippingProgress() >= 1">
              {{ t('gotFreeShippingDiscount') }}
            </b>
          </h3>
        </ion-label>
      </ion-item>
      <ion-progress-bar :color="getFreeShippingProgress() < 1 ? 'primary' : 'success'"
                        :value="getFreeShippingProgress()" style="height: 6px"></ion-progress-bar>
    </div>
    <ion-toolbar>
      <ion-button expand="block" color="tertiary" @click="openCheckoutModal()" :disabled="getCartTotal() <= 0">
        {{ t('cartTotal') }}HK${{ getCartTotal().toLocaleString() }}
        <ion-icon slot="end" :icon="arrowForward"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
// Vue reactivity
import { computed, defineComponent, reactive, ref, watchEffect, onBeforeUnmount, } from 'vue';

// icons
import { arrowBack, arrowBackOutline, close, add, remove, listOutline, arrowForward, expand,
        trashOutline, closeOutline, cartOutline, navigateOutline, walletOutline, pencil, } from 'ionicons/icons';

// components
import { IonHeader, IonFooter, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonRow, IonCol, IonList, IonListHeader, IonItem, IonLabel, IonThumbnail, IonBadge,
        IonSegment, IonSegmentButton, IonCard, IonCardContent, IonSpinner, IonProgressBar, IonChip, IonModal,
        IonInput, IonTextarea, IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonNote,
        loadingController, modalController, isPlatform, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import CheckoutModal from '@/components/order/CheckoutModal.vue';
import DIYProductDesignModal from '@/components/modals/DIYProductDesignModal.vue';
import ImageSlides from '@/components/slides/ImageSlides.vue'

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';
import { utilsDesign } from '@/composables/utilsDesign';

// Konva
import { useKonvaCustomFilters } from '@/composables/useKonvaCustomFilters';
import { Blur } from 'konva/lib/filters/Blur';

// CSS
import '@/theme/diy.css';

export default defineComponent({
  name: 'DIYProductModal',
  props: ["sticker", "sceneWidth", "sceneHeight"],
  components: { IonHeader, IonFooter, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonRow, IonCol, IonList, IonListHeader, IonItem, IonLabel, IonThumbnail, IonBadge,
                IonSegment, IonSegmentButton, IonCard, IonCardContent, IonSpinner, IonProgressBar, IonChip, IonModal,
                IonInput, IonTextarea, IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonNote,
                ImageSlides, LoadingSkeleton, },
  setup(props) {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { getLocalizedStr, formatDate, loadHTMLImg, getHTMLImg: utilsGetHTMLImg, getStageDataURL, sleep, openModal, openImageModal,
            presentPrompt, presentAlert, } = utils();
    const getHTMLImg = async (imgLink) => (await utilsGetHTMLImg(imgLink, 600));
    const { concatCupPreviewPhotos, getCupDesignPhotoBase64, releaseHTMLCanvases, clearKonvaDOMCanvases, } = utilsDesign();

    const products = computed(() => store.getters.getProductsByType('DIY產品'));
    const productCategories = computed(() => store.getters.getProductCategoriesByType('DIY產品'));
    const user = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);

    const selectedCategoryId = ref("all");

    const getCartTotal = () => (products.value.reduce((a, b) => (b.requiredQty > 0 ? +a + (b.sellingPrice * b.requiredQty) : +a), 0));

    // DIY Product Modal
    const stageRef = ref(null);
    const canvas = reactive({
      loading: true,
      loadingPercent: 0,

      sceneWidth: 0,
      sceneHeight: 0,

      productImgWidth: 0,
      productImgHeight: 0,

      loadedProductImg: null,
      loadedStickerImg: null,
      
      stickerImgX: 0,
      stickerImgY: 0,
      
      stickerImgWidth: 256,
      stickerImgHeight: 256,

      blurRadius: 30,

      extraStickerImgOps: {},
    });
    const { Border } = useKonvaCustomFilters();

    const closeModal = async () => { await modalController.dismiss() };

    const setPreviewPhoto = async (product) => {
      try {
        await sleep(0.2);
        const dataURL = getStageDataURL(stageRef.value, "image/webp");
        product.previewPhotoLink = dataURL;
        product.designPhotoLink = canvas.loadedStickerImg.src;
        product.canvasImg = canvas.loadedProductImg;
      } catch (e) {
        console.error(e);
      }
    }
    const genProductPreviewPhoto = async (product) => {
      const { naturalWidth, naturalHeight } = canvas.loadedStickerImg;
      const { categoryId, photoLink, photoWidth, photoHeight, stickerWidth, stickerHeight, stickerRelativeX, stickerRelativeY, photos } = product;

      if (categoryId == 'throwpillows') {
        canvas.loadedProductImg = null;
        canvas.sceneWidth = naturalWidth;
        canvas.sceneHeight = naturalHeight;

        canvas.stickerImgX = 0;
        canvas.stickerImgY = 0;
        canvas.stickerImgWidth = naturalWidth;
        canvas.stickerImgHeight = naturalHeight;
        /*canvas.extraStickerImgOps = {
          //borderColor: 'grey',
          //borderSize: 5,
          shadowBlur: 20,
          shadowColor: 'black',
        };*/
        await sleep(0.1);
        const targetNode = stageRef.value.getStage().findOne('#stickerImg');
        /*targetNode.filters([Blur, Border]);
        await sleep(0.1);*/
        targetNode.filters([Border]);
        targetNode.cache();
        await setPreviewPhoto(product);
        targetNode.filters([]);
      }
      else if (photoLink) {
        const img = await getHTMLImg(photoLink);
        
        canvas.loadedProductImg = img;

        canvas.sceneWidth = photoWidth;
        canvas.sceneHeight = photoHeight;
        
        canvas.productImgWidth = photoWidth;
        canvas.productImgHeight = photoHeight;

        // object-fit: contain
        const scale = Math.min(stickerWidth / naturalWidth, stickerHeight / naturalHeight);
        canvas.stickerImgWidth = naturalWidth * scale;
        canvas.stickerImgHeight = naturalHeight * scale;

        canvas.stickerImgX = stickerRelativeX + (stickerWidth-canvas.stickerImgWidth) / 2;
        canvas.stickerImgY = stickerRelativeY + (stickerHeight-canvas.stickerImgHeight) / 2;
        canvas.extraStickerImgOps = {};

        if (categoryId == 'cups') {
          const getCupPreviewPhotoCanvas = async (productPhoto, noSticker = false) => {
            const { photoLink, photoWidth, photoHeight, stickerRelativeX, stickerRelativeY, stickerWidth, stickerHeight, roundness, } = productPhoto;

            const loadedProductImg = await getHTMLImg(photoLink);
            await sleep(0.5);
            
            // Get canvas elements
            const canvasEl = document.createElement("canvas");
            const ctx = canvasEl.getContext("2d");
            ctx.fillStyle = "#fff"; // white background
            
            // Draw the product image
            canvasEl.width = photoWidth;
            canvasEl.height = photoHeight;
            ctx.drawImage(loadedProductImg, 0, 0, photoWidth, photoHeight, 0, 0, photoWidth, photoHeight);

            if (!noSticker) {
              // Draw the design image
              canvas.stickerImgX = stickerRelativeX + (stickerWidth-canvas.stickerImgWidth) / 2;
              canvas.stickerImgY = stickerRelativeY + (stickerHeight-canvas.stickerImgHeight) / 2;

              const { width: iw, height: ih } = canvas.loadedStickerImg;

              const xOffset = canvas.stickerImgX, yOffset = canvas.stickerImgY; // left & top padding
              const a = canvas.stickerImgWidth / 2; // image width
              const b = 15; // roundness
              const scaleFactor = iw / (2 * a);

              // draw vertical slices
              for (let X = 0; X < iw; X += 1) {
                const y = b / a * Math.sqrt(a * a - (X - a) * (X - a)); // ellipsis equation
                ctx.drawImage(canvas.loadedStickerImg, X*scaleFactor, 0, iw/50, ih, X+xOffset, y+yOffset, 1, canvas.stickerImgHeight);
              }
            }
            return canvasEl; // return the newly created canvas
          }

          // Export the preview image
          const leftCanvas = await getCupPreviewPhotoCanvas(photos.find(p => p.caption == 'leftCupImg'));
          const centerCanvas = await getCupPreviewPhotoCanvas(photos.find(p => p.caption == 'centerCupImg'), true);
          const rightCanvas = await getCupPreviewPhotoCanvas(photos.find(p => p.caption == 'rightCupImg'));
          /*
          const [leftCanvas, centerCanvas, rightCanvas] = await Promise.all([
            getCupPreviewPhotoCanvas(photos.find(p => p.caption == 'leftCupImg')),
            getCupPreviewPhotoCanvas(photos.find(p => p.caption == 'centerCupImg'), true),
            getCupPreviewPhotoCanvas(photos.find(p => p.caption == 'rightCupImg')),
          ]);
          */
          product.leftPreviewPhotoLink = leftCanvas.toDataURL('image/jpeg');
          product.centerPreviewPhotoLink = centerCanvas.toDataURL('image/jpeg');
          product.rightPreviewPhotoLink = rightCanvas.toDataURL('image/jpeg');

          // combined cup photos (side-by-side)
          const [previewPhotoLink, designPhotoLink] = await Promise.all([
            concatCupPreviewPhotos(leftCanvas, centerCanvas, rightCanvas),
            getCupDesignPhotoBase64(canvas.loadedStickerImg, product.exportAspectRatio),
          ]);
          product.previewPhotoLink = previewPhotoLink;
          product.designPhotoLink = designPhotoLink;

          releaseHTMLCanvases([leftCanvas, centerCanvas, rightCanvas]);
        }
        else {
          await setPreviewPhoto(product);
        }
      }
      product.loadedPreviewPhoto = true;
    }
    const loadProductPreviewPhotos = async () => {
      canvas.loading = true;
      const numOfProducts = products.value.length;
      
      // Saved Designs
      const genSavedPreviewPhotos = async (products) => {
        const setPreviewPhotos = async (product, relatedProductDesign) => {
          if (product.photoLink) product.canvasImg = await getHTMLImg(product.photoLink);
          product.previewPhotoLink = relatedProductDesign.previewPhoto;
          product.loadedPreviewPhoto = true;
          canvas.loadingPercent += 1 / numOfProducts;
        }
        const promises = [];
        for (const { product, relatedProductDesign } of products) promises.push(setPreviewPhotos(product, relatedProductDesign));
        return await Promise.all(promises);
      }
      // Cup preview photos
      const genCupPreviewPhotos = async (products) => {
        const promises = [];
        for (const product of products) {
          promises.push(genProductPreviewPhoto(product));
          canvas.loadingPercent += 1 / numOfProducts;
        }
        return await Promise.all(promises);
      }
      // Other preview photos
      const genOtherPreviewPhotos = async (products) => {
        for (const product of products) {
          await genProductPreviewPhoto(product);
          canvas.loadingPercent += 1 / numOfProducts;
        }
      }
      const designedProducts = [], cupProducts = [], pillowProducts = [], otherProducts = [];
      for (const product of products.value) {
        const relatedProductDesign = (props.sticker.designs || []).find(d => d.productId == product.id);
        if (relatedProductDesign && relatedProductDesign.previewPhoto) { // load saved design preview image
          designedProducts.push({ product, relatedProductDesign });
        } else {
          if (product.categoryId == 'cups') cupProducts.push(product);
          else if (product.categoryId == 'throwpillows') pillowProducts.push(product);
          else otherProducts.push(product);
        }
      }
      // Generate preview images for all products
      await genSavedPreviewPhotos(designedProducts);
      await genCupPreviewPhotos(cupProducts);
      await genOtherPreviewPhotos(otherProducts);
      await genOtherPreviewPhotos(pillowProducts);
      //await Promise.all([genSavedPreviewPhotos(designedProducts), genCupPreviewPhotos(cupProducts), genOtherPreviewPhotos(otherProducts)]);
      canvas.loading = false;
      clearKonvaDOMCanvases(stageRef.value.getStage()); // clean-up
    }
    watchEffect(() => {
      loadHTMLImg(props.sticker.outputPhoto, (img) => {
        canvas.loadedStickerImg = img;
        setTimeout(loadProductPreviewPhotos, 250);
      })
    });
    onBeforeUnmount(() => {
      clearKonvaDOMCanvases(stageRef.value.getStage());
      for (const product of products.value) {
        product.loadedPreviewPhoto = false; // reset loading state
        product.canvasJson = null;
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, close, add, remove, listOutline, arrowForward, expand,
      trashOutline, closeOutline, cartOutline, navigateOutline, walletOutline, pencil,

      // variables
      products, productCategories,
      selectedCategoryId,
      canvas, stageRef,
      settings,

      // methods
      t, getLocalizedStr,
      closeModal, formatDate,
      getCartTotal,
      getFreeShippingProgress: () => (getCartTotal() / settings.value.freeShippingMinOrderTotal),

      decrementCartItemQty: (product, minQty = 0) => {
        if (product.requiredQty >= (minQty+1)) product.requiredQty--;
      },
      incrementCartItemQty: (product) => {
        product.requiredQty = (product.requiredQty || 0) + 1;
      },
      onFinishInputCartItemQty: (e, product) => {
        const inputQty = e.target.value;
        product.requiredQty = inputQty >= 0 ? Math.ceil(+inputQty) : +product.requiredQty + 1;
      },

      openImageModal,
      openCheckoutModal: async () => (await openModal(CheckoutModal, { stickerId: props.sticker.id, products: products.value.filter(p => (p.requiredQty > 0))})),

      openDIYProductDesignModal: async (product, sticker) => {
        const { sceneWidth, sceneHeight } = props;
        const relatedProductDesign = (sticker.designs || []).find(d => d.productId == product.id);
        if (relatedProductDesign) { // load saved design
          const { canvasJson, productImgColor } = relatedProductDesign;
          product.canvasJson = canvasJson;
          product.imgColor = productImgColor;
        }
        console.log(sticker);
        const modal = await modalController.create({
          component: DIYProductDesignModal,
          componentProps: { product, sticker: JSON.parse(JSON.stringify(sticker)), sceneWidth, sceneHeight },
        });
        modal.onDidDismiss().then(async ({ data }) => {
          if (data) {
            const { previewPhotoBase64, designPhotoBase64, productPhotoBase64, canvasJson, productImgColor,
                    leftPreviewPhotoLink, centerPreviewPhotoLink, rightPreviewPhotoLink, } = data;
            product.canvasJson = canvasJson;
            product.previewPhotoLink = previewPhotoBase64;
            product.designPhotoLink = designPhotoBase64;
            product.imgColor = productImgColor; // custom color for products (RGB filters)

            product.leftPreviewPhotoLink = leftPreviewPhotoLink;
            product.centerPreviewPhotoLink = centerPreviewPhotoLink;
            product.rightPreviewPhotoLink = rightPreviewPhotoLink;
            
            product.requiredQty = product.requiredQty || 1; // automatically add the product to cart
          }
        });
        return modal.present();
      }
    }
  }
})
</script>

<style scoped>
  img {
    margin: 0 auto;
  }
  .cup-preview-img {
    max-height: 150px;
    max-width: 80% !important;
  }
  .quantity-input-item {
    display: table;
    margin: 0 auto 6px;
  }
  .quantity-input-item::part(native) {
    margin-inline-end: -16px;
    width: 150%;
  }
  .quantity-input {
    background-color: white;
    color: #000 !important;
    text-align: center;
    border: 0;
    width: 5ch;
  }
  .qty-action-btn {
    font-size: 10px;
  }
</style>